import { API_TYPES, REVIEW_TYPES } from 'config/enums'
import IPaginator from 'interfaces/common/IPaginator'
import IReviewType from 'interfaces/review/IReviewType'

export const COMMENT_TEXT_EDITOR_MAX_LENGTH = 1000
export const TOAST_DURATION = 5000
export const COMMENTS_PER_PAGE = 10
export const ERR_REQ_FIELD_APP = 'Please fix the errors in the form'
export const MSG_ALREADY_SAVED_DATA = 'All changes are already saved'
export const MSG_SAVED_DATA = 'All changes have been saved'
export const PDF_FONT_FAMILY_NAME = 'Open Sans'
export const DOCX_FONT_FAMILY_NAME = 'Calibri'
export const PDF_FONTS_SRC = [
  { src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf' },
  { src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-600.ttf', fontWeight: 600 },
  { src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-300.ttf', fontWeight: 300 },
]

const developmentApi: Record<API_TYPES, string> = {
  QUESTIONNAIRE_API: 'https://questionnaire-api-ch-elektra.os-dev.io/api',
  PROJECT_API: 'https://project-api-ch-elektra.os-dev.io/api',
  APP_NAMESPACE: 'APP_PROFILE_98c60c01-112c-47e1-b175-5905d727c919',
  FACADE_API: 'https://facade-api-ch-elektra.os-dev.io/api',
  MASTER_API: 'https://master-data-api-ch-elektra.os-dev.io/api',
}

const productionApi: Record<API_TYPES, string> = {
  QUESTIONNAIRE_API: 'https://questionnaire-api-ch-namor.os-dev.io/api',
  PROJECT_API: 'https://project-api-ch-namor.os-dev.io/api',
  APP_NAMESPACE: '',
  FACADE_API: 'https://facade-api-ch-namor.os-dev.io/api',
  MASTER_API: 'https://master-data-api-ch-namor.os-dev.io/api',
}

export const apiConfig = Object.keys(API_TYPES).reduce((acc, type) => {
  const api =
    process.env.NODE_ENV !== 'development'
      ? process.env[`REACT_APP_${type}`] ?? productionApi[type as API_TYPES]
      : developmentApi[type as API_TYPES]
  return { ...acc, [type]: api }
}, {} as Record<API_TYPES, string>)

export const APP_EDITOR_PERMISSION = apiConfig[API_TYPES.APP_NAMESPACE]
  ? `${apiConfig[API_TYPES.APP_NAMESPACE]}:PREREQUISITES_APP_FORM_COMPLETE`
  : 'PREREQUISITES_APP_FORM_COMPLETE'

export const REVIEW_TYPES_DATA: IReviewType[] = [
  {
    key: REVIEW_TYPES.ANY_REVIEW,
    title: 'review.type.anyReview.title',
    description: 'review.type.anyReview.description',
  },
  {
    key: REVIEW_TYPES.MAJORITY_OF_REVIEWS,
    title: 'review.type.majority.title',
    description: 'review.type.majority.description',
  },
  {
    key: REVIEW_TYPES.ALL_REVIEWS,
    title: 'review.type.all.title',
    description: 'review.type.all.description',
  },
]

export const DATE_TIME_FORMAT = 'MM/DD/yyyy hh:mm A'

export const TEXT_AREA_CHARACTER_LIMIT = 1000

export const TEXT_AREA_WARNING_THRESHOLD = 900

export const DEFAULT_PAGINATOR_DATA: IPaginator = {
  itemsPerPage: 5,
  page: 1,
  totalItems: 0,
  totalPages: 0,
}

export const DEFAULT_TABLE_PAGINATION_ITEMS_PER_PAGE: number[] = [2, 5, 10]
export const DEFAULT_TABLE_PAGINATION_THRESHOLD: number = 5
